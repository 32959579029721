import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import { TableType } from '../../../enum'

interface InputRadioProps {
  /**
   * content of row which has this input radio
   */
  el: any
  /**
   * type of modal: Attendu, Commande
   */
  type: TableType
  /**
   * event check when onclick radio
   * @returns void
   */
  handleCheckedRadio: () => void
}

/**
 * The @InputRadio is a component display radio button inside table. The default checked radio is the first display item on the list
 * @author [anhnq]
 * @version 0.1
 */
const InputRadio = (props: InputRadioProps) => {
  const { el, type, handleCheckedRadio } = props
  const { currentFournisseur } = useSelector(
    (state: RootState) => state.fournisseur
  )
  const { currentDestinataire } = useSelector(
    (state: RootState) => state.destinataire
  )

  const [isDisabled, setIsDisabled] = useState<boolean>(false)

  useEffect(() => {
    if (currentFournisseur.fournisseur) {
      if (type === TableType.ADDRESSE) {
        if (currentFournisseur.fournisseur?.addresse.length === 1) {
          setIsDisabled(true)
        } else {
          setIsDisabled(false)
        }
      } else if (type === TableType.CONTACT) {
        if (currentFournisseur.fournisseur?.contact.length === 1) {
          setIsDisabled(true)
        } else {
          setIsDisabled(false)
        }
      }
    }
    if (currentDestinataire.destinataire) {
      if (type === TableType.ADDRESSE) {
        if (currentDestinataire.destinataire?.addresse.length === 1) {
          setIsDisabled(true)
        } else {
          setIsDisabled(false)
        }
      } else if (type === TableType.CONTACT) {
        if (currentDestinataire.destinataire?.contact.length === 1) {
          setIsDisabled(true)
        } else {
          setIsDisabled(false)
        }
      }
    }

    let elements = document.getElementsByName(`radio-${type}`)
    if (elements.length > 0) {
      let radioFirst = elements[0] as HTMLInputElement
      radioFirst.checked = true
    } else {
      return
    }
  }, [currentDestinataire, currentFournisseur, type])

  return (
    <input
      type="radio"
      name={`radio-${type}`}
      id={el.id}
      disabled={isDisabled}
      onClick={handleCheckedRadio}
    />
  )
}

export default InputRadio
