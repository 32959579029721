import { Button, Form, Input, notification } from 'antd'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../app/store'
import CLOSE_ICON from '../../../assets/images/close-success.png'
import ERROR from '../../../assets/images/error.png'
import PLUS from '../../../assets/images/plus.png'
import '../../../assets/scss/noti.scss'
import countryList from '../../../constants/countryList.json'
import Define from '../../../constants/define'
import { FormCreationType, StatusAPI } from '../../../enum'
import {
  Adresses,
  Contact,
  Destinataire,
  Fournisseur,
  Transporteur,
} from '../../../models'
import {
  clearCurrentDestinataire,
  createNewDestinataire,
} from '../../../redux/reducers/destinataireSlice'
import {
  clearCurrentFournisseur,
  createNewFournisseur,
} from '../../../redux/reducers/fournisseurSlice'
import {
  clearCurrentTransporteur,
  createNewTransporteur,
} from '../../../redux/reducers/transporteurSlice'
import {
  genKey,
  removeTones,
  sleep,
  validateHasSameLastDigit,
} from '../../../utils'
import AutocompletePays from '../AutoCompletePays'
import CSwitch from '../CSwitch/CSwitch'
import './index.scss'

const OPTION_INTL_TEL_INPUT = {
  initialCountry: 'fr',
  preferredCountries: ['fr', 'us', 'vn'],
  utilsScript:
    'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.8/js/utils.js',
}

interface FormCreationInterface {
  /**
   * set state is create form
   */
  isCreateForm?: boolean
  /**
   * function close form
   * @returns boolean
   */
  onCloseForm?: () => void
  /**
   * modal type of form
   */
  type: FormCreationType
  previousId?: string
  nextId?: string
}

/**
 * The @FormCreation is a form to create information
 * @author [anhnq]
 * @version 0.1
 */
const FormCreation = (props: FormCreationInterface) => {
  const {
    isCreateForm = false,
    type,
    onCloseForm = () => {},
    previousId,
  } = props
  const dispatch = useDispatch()
  const locationKey = useSelector((state: RootState) => state.selector).data
    .location
  const { currentFournisseur } = useSelector(
    (state: RootState) => state.fournisseur
  )
  const { currentTransporteur } = useSelector(
    (state: RootState) => state.transporteur
  )
  const { currentDestinataire } = useSelector(
    (state: RootState) => state.destinataire
  )
  const { currentCommande } = useSelector((state: RootState) => state.commande)

  const [errorCode, setErrorCode] = useState<string>('')
  const [errorPays, setErrorPays] = useState<boolean>(false)
  const [form] = Form.useForm()

  const client_code_nom =
    localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || ''
  const initialValueAdresse: Adresses[] = [
    {
      addresse: '',
      code_postal: '',
      ville: '',
      pays: '',
      gln: '',
      complement: '',
    },
  ]

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialValueContact: Contact[] = [
    {
      nom: '',
      tel: '',
      mobile: '',
      email: '',
    },
  ]

  useEffect(() => {
    initialValueContact.forEach((it, idx) => {
      const phoneInputField = document.querySelector(`#${type}tel${idx}`)
      if (phoneInputField)
        (window as any).intlTelInput(phoneInputField, OPTION_INTL_TEL_INPUT)
      const mobileInputField = document.querySelector(`#${type}mobile${idx}`)
      if (mobileInputField)
        (window as any).intlTelInput(mobileInputField, OPTION_INTL_TEL_INPUT)
      return initialValueContact
    })
  }, [initialValueContact, type])

  useEffect(() => {
    const MAX_RETRY = 10
    const KEY_DIGIT = 10
    let keyOb = genKey(KEY_DIGIT)
    let count = 0
    while (!keyOb[1] && count < MAX_RETRY) {
      keyOb = genKey(KEY_DIGIT)
      sleep(10)
      count++
    }
    if (!keyOb[1]) {
      alert('Can not auto gen code!')
    } else {
      form.setFieldValue('code', keyOb[0])
      form.setFieldValue('relay', false)
      form.setFieldValue('saisir_camion', false)
    }
  }, [form, isCreateForm])

  useEffect(() => {
    if (
      (currentFournisseur.error.includes(
        'duplicate key value violates unique constraint'
      ) &&
        type === FormCreationType.FOURNISSEUR) ||
      (currentTransporteur.error.includes(
        'duplicate key value violates unique constraint'
      ) &&
        type === FormCreationType.LIVRAISON) ||
      (currentDestinataire.error.includes(
        'duplicate key value violates unique constraint'
      ) &&
        type === FormCreationType.DESTINATAIRE)
    ) {
      setErrorCode('Code déjà existant')
    } else if (
      (currentFournisseur.error && type === FormCreationType.FOURNISSEUR) ||
      (currentTransporteur.error && type === FormCreationType.LIVRAISON) ||
      (currentDestinataire.error && type === FormCreationType.DESTINATAIRE)
    ) {
      notification.open({
        className: 'noti noti-error',
        message: (
          <div className="flex items-center">
            <img src={ERROR} alt="error" width={50} className="mr-2.5" />
            Une erreur s'est produite. Merci de réessayer ultérieurement
          </div>
        ),
        placement: 'topRight',
        closeIcon: <img src={CLOSE_ICON} alt="close" className="ml-28" />,
        duration: 3,
      })
      if (
        (currentFournisseur.error.includes('length of pays is wrong') &&
          type === FormCreationType.FOURNISSEUR) ||
        (currentDestinataire.error.includes('length of pays is wrong') &&
          type === FormCreationType.DESTINATAIRE) ||
        (currentTransporteur.error.includes('length of pays is wrong') &&
          type === FormCreationType.LIVRAISON)
      ) {
        setErrorPays(true)
      }
    }
  }, [
    currentDestinataire.error,
    currentFournisseur.error,
    currentTransporteur.error,
    type,
  ])

  useEffect(() => {
    if (
      currentFournisseur.status === StatusAPI.failure &&
      type === FormCreationType.FOURNISSEUR
    ) {
      dispatch(clearCurrentFournisseur())
    }
    if (
      currentTransporteur.status === StatusAPI.failure &&
      type === FormCreationType.LIVRAISON
    ) {
      dispatch(clearCurrentTransporteur())
    }
    if (
      currentDestinataire.status === StatusAPI.failure &&
      type === FormCreationType.DESTINATAIRE
    ) {
      dispatch(clearCurrentDestinataire())
    }
  }, [
    currentDestinataire.status,
    currentFournisseur.status,
    currentTransporteur.status,
    dispatch,
    type,
  ])

  useEffect(() => {
    if (currentCommande.status === StatusAPI.failure) {
      setErrorCode('')
      setErrorPays(false)
    }
  }, [currentCommande.status])

  //find value in country list
  function findPay(pay: string) {
    let status = false
    for (var i = 0; i < countryList.length; i++) {
      if (countryList[i].value === pay) {
        status = true
        break
      }
    }
    return status
  }

  //Onfinish if all required fields are filled. If all field is filled, form can be submitted.
  const onFinish = (values: Fournisseur | Transporteur | Destinataire) => {
    //find if value of pays input match country list
    let listIdx = []
    for (let i = 0; i < values.addresse.length; i++) {
      let ob = document.getElementById(
        `${type}dynamic-form-address_addresse_${i}_pays`
      )

      let value = ob?.getElementsByTagName('input')[0].value

      let findElement = findPay(value as string)
      if (!findElement) {
        listIdx.push(i)
        value = ''
      }
    }

    //if value of pays input match country list
    if (listIdx.length === 0) {
      switch (type) {
        case FormCreationType.FOURNISSEUR:
          dispatch(
            createNewFournisseur({
              ...values,
              client_fournisseur: [
                {
                  client_code_nom,
                  fournisseur_code: values.code,
                },
              ],
            })
          )

          break
        case FormCreationType.LIVRAISON:
          dispatch(
            createNewTransporteur({
              ...values,
              client_transporteur: [
                {
                  client_code_nom,
                  transporteur_code: values.code,
                },
              ],
            })
          )
          break
        case FormCreationType.DESTINATAIRE:
          dispatch(
            createNewDestinataire({
              ...values,
              client_destinataire: [
                {
                  client_code_nom,
                  destinataire_code: values.code,
                },
              ],
            })
          )
          break
        default:
          break
      }
    } else {
      //if value of pays input not match country list => border is red
      for (let i = 0; i < listIdx.length; i++) {
        let ob = document.getElementById(
          `${type}dynamic-form-address_addresse_${listIdx[i]}_pays`
        )

        let divTag = ob?.getElementsByClassName(
          'ant-select-selector'
        )[0] as HTMLElement
        divTag.style.border = '1px solid red'
      }
    }
  }

  //Onfinish failed if one of required fields is missing
  const onFinishFailed = ({ values, errorFields, outOfDate }: any) => {
    let listIdx = []
    for (let i = 0; i < values.addresse.length; i++) {
      let ob = document.getElementById(
        `${type}dynamic-form-address_addresse_${i}_pays`
      )
      let value = ob?.getElementsByTagName('input')[0].value

      let findElement = findPay(value as string)
      if (!findElement) {
        listIdx.push(i)
        value = ''
      }
    }
    for (let i = 0; i < listIdx.length; i++) {
      let ob = document.getElementById(
        `${type}dynamic-form-address_addresse_${listIdx[i]}_pays`
      )

      let divTag = ob?.getElementsByClassName(
        'ant-select-selector'
      )[0] as HTMLElement
      divTag.style.border = '1px solid red'
    }
  }

  //set border not red anymore if pays fields are changed
  const onFieldsChange = (changedFields: any, allFields: any) => {
    let index = changedFields[0].name[1]
    let isPayChange = changedFields[0].name[2] === 'pays'
    if (index !== null && isPayChange) {
      let ob = document.getElementById(
        `${type}dynamic-form-address_addresse_${index}_pays`
      )
      if (ob !== null) {
        let divTag = ob?.getElementsByClassName(
          'ant-select-selector'
        )[0] as HTMLElement
        if (divTag !== null) {
          divTag.style.border = '1px solid #d9d9d9'
        }
      }
    }
  }

  const closeCreateForm = () => {
    form.resetFields()
    onCloseForm()
  }

  const addContact = (count: number) => {
    setTimeout(() => {
      const phoneInputField = document.querySelector(`#${type}tel${count}`)
      ;(window as any).intlTelInput(phoneInputField, OPTION_INTL_TEL_INPUT)

      const mobileInputField = document.querySelector(`#${type}mobile${count}`)
      ;(window as any).intlTelInput(mobileInputField, OPTION_INTL_TEL_INPUT)
    }, 20)
  }

  const onChangeCode = (newValue: string) => {
    setErrorCode('')
    form.setFieldValue('code', removeTones(newValue))
  }

  const validateField = () => {
    const fields = form.getFieldsValue()
    fields.contact.forEach((element: any, index: number) => {
      if (element === undefined) {
        ;(
          document.getElementById(`${type}tel${index}`) as any
        ).style.borderColor = 'red'
      } else {
        if (element.tel === '' || element.tel === undefined) {
          ;(
            document.getElementById(`${type}tel${index}`) as any
          ).style.borderColor = 'red'
        }
      }
    })
  }

  const onChangeMobile = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => {
    const element = document.getElementById(`${type}mobile${key}`) as any
    element.value = event.target.value
      .replace(/([^0-9+])/gi, '')
      .substring(0, 20)
  }

  const onChangeTel = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: number
  ) => {
    const element = document.getElementById(`${type}tel${key}`) as any
    if (event.target.value === '') {
      element.style.borderColor = 'red'
    } else {
      element.style.borderColor = '#d9d9d9'
    }
    element.value = event.target.value
      .replace(/([^0-9+])/gi, '')
      .substring(0, 20)
  }

  const onChangeValueFournisseur = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const element = document.getElementById(id) as any
    const value = event.target.value
    const isValid = validateHasSameLastDigit(value as unknown as number)
    if ((value.length === 13 && isValid) || !value) {
      element.style.borderColor = '#d9d9d9'
    } else {
      element.style.borderColor = 'red'
    }
    element.value = event.target.value
      .replace(/([^0-9+])/gi, '')
      .substring(0, 13)
  }

  return (
    <>
      <div className="bg-gray2 py-1 px-4 rounded-lg mb-4 ">
        <p className="text-primary font-bold text-lg border-0 border-b border-b-gray3 border-solid pb-4">
          {type}
        </p>
        <Form
          id="creation-form"
          name={`${type}dynamic-form-address`}
          className="dynamic-form-address"
          onFinish={onFinish}
          onFieldsChange={onFieldsChange}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          form={form}
        >
          <div className="flex mb-5 justify-between">
            <div className="flex">
              <Form.Item
                label={
                  type === FormCreationType.LIVRAISON
                    ? 'Code Transporteur'
                    : `Code ${type}`
                }
                name="code"
                colon={false}
                className="form-item-no-require form-label"
                rules={[{ required: true, message: '' }]}
                validateStatus={
                  errorCode || form.getFieldValue('code') === ''
                    ? 'error'
                    : undefined
                }
                help={errorCode}
              >
                <Input
                  id={`${type}-${locationKey['code']}`}
                  data-next-id={`${type}-${locationKey['nom']}`}
                  data-previous-id={previousId}
                  style={{ width: '7rem' }}
                  className="ml-5"
                  onChange={(e) => onChangeCode(e.target.value)}
                  maxLength={10}
                />
              </Form.Item>
              <Form.Item
                label={
                  type === FormCreationType.LIVRAISON
                    ? 'Nom Transporteur'
                    : `Nom ${type}`
                }
                name="nom"
                className="form-item-no-require form-label"
                colon={false}
                rules={[{ required: true, message: '' }]}
              >
                <Input
                  id={`${type}-${locationKey['nom']}`}
                  data-previous-id={`${type}-${locationKey['code']}`}
                  data-next-id={`${type}-${locationKey['code-relay']}, ${type}-${locationKey['Non-retour-en-date']}, ${type}-${locationKey['Adresse']}-0`}
                  style={{ width: '14rem' }}
                  className="ml-5"
                  maxLength={32}
                />
              </Form.Item>
            </div>
            {type === FormCreationType.LIVRAISON && (
              <Form.Item
                label="Code relay"
                name="relay"
                colon={false}
                valuePropName="checked"
              >
                <CSwitch
                  id={`${type}-${locationKey['code-relay']}`}
                  previousId={`${type}-${locationKey['nom']}`}
                  nextId={`${type}-${locationKey['saisir-camion']}`}
                  className="ml-5 mr-10 custom-switch"
                />
              </Form.Item>
            )}
            {type === FormCreationType.LIVRAISON && (
              <Form.Item
                label="Saisir n° camion"
                name="saisir_camion"
                colon={false}
                valuePropName="checked"
              >
                <CSwitch
                  id={`${type}-${locationKey['saisir-camion']}`}
                  previousId={`${type}-${locationKey['code-relay']}`}
                  nextId={`${type}-${locationKey['Adresse']}-0`}
                  defaultChecked={false}
                  className="ml-5 mr-10 custom-switch"
                />
              </Form.Item>
            )}
            {type === FormCreationType.DESTINATAIRE && (
              <Form.Item
                label=" Non retour en date "
                name="nom_retour_date"
                colon={false}
                valuePropName="checked"
              >
                <CSwitch
                  id={`${type}-${locationKey['Non-retour-en-date']}`}
                  previousId={`${type}-${locationKey['nom']}`}
                  nextId={`${type}-${locationKey['Adresse']}-0`}
                  className="ml-5 mr-10 custom-switch"
                />
              </Form.Item>
            )}
          </div>
          <div
            className="flex pb-2 form-creation-scroll"
            style={{ overflow: 'auto' }}
          >
            <div className="block mr-5">
              <Form.List name="addresse" initialValue={initialValueAdresse}>
                {(fields, { add }) => (
                  <>
                    <div className="flex">
                      <div className="custom-table-cell">
                        <div
                          className="flex items-center justify-center font-semibold"
                          style={{ width: 165 }}
                        >
                          <span className="require-filed form-label mt-0">
                            Adresse
                          </span>

                          <img
                            src={PLUS}
                            alt="plus"
                            className="ml-2 w-5 cursor-pointer"
                            onClick={() => {
                              add()
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="custom-table-cell flex justify-center"
                        style={{ width: 125 }}
                      >
                        <span className=" form-label mt-0">Complément</span>
                      </div>
                      <div
                        className="custom-table-cell flex justify-center font-semibold"
                        style={{ width: 85 }}
                      >
                        <span className="require-filed form-label mt-0">
                          CP
                        </span>
                      </div>
                      <div
                        className="custom-table-cell flex justify-center font-semibold"
                        style={{ width: 85 }}
                      >
                        <span className="require-filed form-label mt-0">
                          Ville
                        </span>
                      </div>
                      <div
                        className="custom-table-cell flex justify-center font-semibold"
                        style={{ width: 120 }}
                      >
                        <span className="require-filed form-label mt-0">
                          Pays
                        </span>
                      </div>
                      <div
                        className="custom-table-cell flex justify-center"
                        style={{ width: 125, borderRight: '1px solid #E5E5E5' }}
                      >
                        <span className=" form-label mt-0">GLN</span>
                      </div>
                    </div>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <div
                        key={key}
                        style={{ display: 'flex' }}
                        className={`form-list ${
                          index % 2 === 0 ? 'bg-[#F9F9F9]' : 'bg-[#FDFDFD]'
                        }`}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 'addresse']}
                          rules={[{ required: true, message: '' }]}
                        >
                          <div
                            style={{
                              borderRight: '1px solid #E5E5E5',
                              padding: 10,
                              width: 165,
                            }}
                          >
                            <Input
                              id={`${type}-${locationKey['Adresse']}-${key}`}
                              data-previous-id={
                                index === 0
                                  ? `${type}-${locationKey['saisir-camion']}, ${type}-${locationKey['Non-retour-en-date']}`
                                  : null
                              }
                              // data-next-id={`${type}-${locationKey['Complément']}-${key}`}
                              maxLength={32}
                            />
                          </div>
                        </Form.Item>
                        <Form.Item {...restField} name={[name, 'complement']}>
                          <div
                            style={{
                              borderRight: '1px solid #E5E5E5',
                              padding: 10,
                              width: 125,
                            }}
                          >
                            <Input maxLength={32} />
                          </div>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'code_postal']}
                          rules={[{ required: true, message: '' }]}
                        >
                          <div
                            style={{
                              borderRight: '1px solid #E5E5E5',
                              padding: 10,
                              width: 85,
                            }}
                          >
                            <Input maxLength={10} />
                          </div>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'ville']}
                          rules={[{ required: true, message: '' }]}
                        >
                          <div
                            style={{
                              borderRight: '1px solid #E5E5E5',
                              padding: 10,
                              width: 85,
                            }}
                          >
                            <Input maxLength={32} />
                          </div>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'pays']}
                          rules={[{ required: true, message: '' }]}
                          validateStatus={errorPays ? 'error' : undefined}
                        >
                          <div
                            style={{
                              borderRight: '1px solid #E5E5E5',
                              padding: 10,
                              width: 120,
                            }}
                          >
                            <AutocompletePays
                              cbUpdateForm={({
                                name,
                                selectedValue,
                              }: {
                                name: string
                                selectedValue: string
                              }) => {
                                const fields = Object.assign(
                                  form.getFieldsValue()
                                )

                                fields.addresse[key]['pays'] = selectedValue
                                  .split('(')[0]
                                  .trim()
                                fields.addresse[key]['iso'] = selectedValue
                                  .split('(')[1]
                                  .replace(/[)]/g, '')
                                  .trim()
                                form.setFieldsValue(fields)
                              }}
                              onChangeField={() => {
                                setErrorPays(false)
                              }}
                            />
                          </div>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'gln']}
                          rules={[
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value) {
                                  return Promise.resolve()
                                } else {
                                  const isValid =
                                    validateHasSameLastDigit(value)
                                  if (value.length === 13 && isValid) {
                                    return Promise.resolve()
                                  }
                                }

                                return Promise.reject(
                                  new Error('Entrée invalide')
                                )
                              },
                            }),
                          ]}
                        >
                          <div style={{ padding: 10, width: 133 }}>
                            <input
                              maxLength={13}
                              className="custom-tel-no custom-gln"
                              style={{ width: '100%' }}
                              onKeyPress={(event: any) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault()
                                }
                              }}
                              onChange={(event) => {
                                onChangeValueFournisseur(
                                  event,
                                  `${type}gln${key}`
                                )
                              }}
                              id={`${type}gln${key}`}
                            />
                          </div>
                        </Form.Item>
                      </div>
                    ))}
                  </>
                )}
              </Form.List>
            </div>
            <div className="block">
              <Form.List name="contact" initialValue={initialValueContact}>
                {(fields, { add }) => (
                  <>
                    <div className="flex">
                      <div className="custom-table-cell" style={{ width: 160 }}>
                        <div className="flex items-center justify-center font-semibold">
                          <span className="require-filed form-label mt-0">
                            Contact
                          </span>
                          <img
                            src={PLUS}
                            alt="plus"
                            className="ml-2 w-5 cursor-pointer"
                            onClick={() => {
                              add()
                              addContact(fields.length)
                            }}
                          />
                        </div>
                      </div>
                      <div
                        className="custom-table-cell flex justify-center font-semibold"
                        style={{ width: 160 }}
                      >
                        <span className="require-filed form-label mt-0">
                          Tel
                        </span>
                      </div>
                      <div
                        className="custom-table-cell flex justify-center"
                        style={{ width: 150 }}
                      >
                        <span className=" form-label mt-0">Mobile</span>
                      </div>
                      <div
                        className="custom-table-cell flex justify-center font-semibold"
                        style={{ width: 230, borderRight: 'none' }}
                      >
                        <span className="require-filed form-label mt-0">
                          Email
                        </span>
                      </div>
                    </div>
                    {fields.map(({ key, name, ...restField }) => (
                      <div
                        key={key}
                        style={{ display: 'flex' }}
                        className="form-list"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 'nom']}
                          rules={[{ required: true, message: '' }]}
                        >
                          <div
                            style={{
                              borderRight: '1px solid #E5E5E5',
                              padding: 10,
                              width: 160,
                            }}
                          >
                            <Input maxLength={32} />
                          </div>
                        </Form.Item>

                        <Form.Item
                          {...restField}
                          name={[name, 'tel']}
                          rules={[{ required: true, message: '' }]}
                        >
                          <div
                            style={{
                              borderRight: '1px solid #E5E5E5',
                              padding: 10,
                              width: 160,
                            }}
                          >
                            <input
                              className="custom-tel-no"
                              onChange={(event) => {
                                onChangeTel(event, key)
                              }}
                              id={`${type}tel${key}`}
                              type="tel"
                              name={`${type}tel${key}`}
                              placeholder="+33123456789"
                            />
                          </div>
                        </Form.Item>

                        <Form.Item {...restField} name={[name, 'mobile']}>
                          <div
                            style={{
                              borderRight: '1px solid #E5E5E5',
                              padding: 10,
                              width: 150,
                            }}
                          >
                            <input
                              className="custom-tel-no"
                              onChange={(event) => {
                                onChangeMobile(event, key)
                              }}
                              id={`${type}mobile${key}`}
                              type="tel"
                              name={`${type}mobile${key}`}
                              placeholder="+33123456789"
                            />
                          </div>
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'email']}
                          rules={[
                            { required: true, message: '' },
                            { type: 'email', message: 'Entrée Invalide' },
                          ]}
                        >
                          <div style={{ padding: 10, width: 230 }}>
                            <Input maxLength={100} />
                          </div>
                        </Form.Item>
                      </div>
                    ))}
                  </>
                )}
              </Form.List>
            </div>
          </div>

          <Form.Item>
            <div className="flex">
              <Button
                className="bg-green text-white border-white mt-5 mr-5 font-semibold"
                type="primary"
                htmlType="submit"
                onClick={validateField}
              >
                Sauvegarder
              </Button>
              <Button
                className="bg-transparent text-red border-red mt-5 font-semibold"
                onClick={closeCreateForm}
              >
                Annuler
              </Button>
            </div>
          </Form.Item>
        </Form>
        <hr className="border-0 border-b border-b-gray3 border-solid pt-4 mb-4" />
      </div>
    </>
  )
}

export default FormCreation
